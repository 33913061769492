<template>
  <div class="specificationMessage-container">
    <div class="header2">
      <van-nav-bar
        title="产品套餐展示"
        left-text="返回列表"
        left-arrow
        @click-left="onClickLeft"
      ></van-nav-bar>
    </div>
    <div class="specification_h1">
      <div
        class="specificationList"
        v-for="(item, index) in getspecificationList"
        :key="index"
      >
        <van-cell-group class="specs_f">
          <van-cell
            class="specs_cs"
            :title="'套餐' + Number(1 + index) + ':'"
            :value="item.specsName"
          >
            <template #default>
              <span v-html="html_decode(item.specsName)"></span>
            </template>
          </van-cell>
        </van-cell-group>
        <template>
          <div class="specs_content">
            <van-cell-group class="first_list">
              <van-cell
                title="市场原价:"
                :value="item.originalPrice | currency('¥')"
              />
              <div class="hr_cs"></div>
            </van-cell-group>
            <van-cell-group>
              <van-cell
                title="全网最低:"
                :value="item.minSellingPrice | currency('¥')"
              />
              <div class="hr_cs"></div>
            </van-cell-group>
            <van-cell-group>
              <van-cell
                title="拟定售价:"
                :value="item.sellingPrice | currency('¥')"
              />
              <div class="hr_cs"></div>
            </van-cell-group>
            <van-cell-group>
              <van-cell
                title="供货结算价:"
                :value="item.settlementPrice | currency('¥')"
              />
              <div class="hr_cs"></div>
            </van-cell-group>
            <van-cell-group>
              <van-cell title="常备库存:" :value="item.agreementStock" />
              <div class="hr_cs"></div>
            </van-cell-group>
            <van-cell-group class="last_list">
              <van-cell
                title="渠道控价:"
                :value="item.controlPrice | currency('¥')"
              />
            </van-cell-group>
            <span @click="applyProduct" class="addsoec">添加规格</span>
          </div>
        </template>
      </div>
    </div>
  </div>
</template>
<script>
import { ImagePreview } from "vant";
import { Notify } from "vant";
import { Toast } from "vant";
import { currency } from "./input-monry";
export default {
  data() {
    return {
      active: 1,
      getspecificationList: [],
    };
  },
  created() {
    Toast.loading({
      message: "加载中...",
      overlay: true,
      duration: 300,
      forbidClick: true,
    });
    setTimeout(() => {
      this.getspecificationData();
    }, 100);
  },
  filters: {
    currency: currency,
  },
  methods: {
    applyProduct() {
      this.$router.push({
        path: "/applyProductCombo",
        query: {
          ids:
            this.$route.query.id == undefined
              ? this.$route.query.id1
              : this.$route.query.id,
        },
      });
    },
    // 识别后台传递过来的转义符部分无法识别
    html_decode(str) {
      if (str == undefined || str == "") {
        return "-";
      } else {
        var s = "";
        if (str.length == 0) return "";
        s = str.replace(/&amp;/g, "&");
        s = s.replace(/&lt;/g, "<");
        s = s.replace(/&gt;/g, ">");
        s = s.replace(/&nbsp;/g, " ");
        s = s.replace(/&#39;/g, "'");
        s = s.replace(/&amp;/g, "&");
        s = s.replace(/&quot;/g, '"');
        s = s.replace(/<br\/>/g, "\n");
        return s;
      }
    },
    onClickLeft() {
      this.$router.push({
        path: "/productAudit",
        query: {},
      });
    },
    showImg() {
      ImagePreview(this.imgList);
    },
    auditProductDetails() {
      this.$router.push({
        path: "/auditProductDetails",
        query: {
          id:
            this.$route.query.id == undefined
              ? this.$route.query.id1
              : this.$route.query.id,
        },
      });
    },
    getspecificationData() {
      this.$http
        .ajax(
          "/ffkj-main/merchantProductSpecs/list",
          {
            productId:
              this.$route.query.id == undefined
                ? this.$route.query.id1
                : this.$route.query.id,
          },
          "get",
          {}
        )
        .then((res) => {
          if (res.code == 0) {
            this.getspecificationList = res.data;
          } else {
            Notify({ type: "danger", message: res.message });
          }
        });
    },
  },
};
</script>
<style scoped></style>
